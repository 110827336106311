.create-survey-dialog {
  &__section-header {
    font-weight: normal;
    font-size: 18px;
    margin-right: 20px;

    &--column {
      margin-bottom: 4px;
    }
  }

  &__row {
    display: flex;
    align-items: center;
  }

  &__name-field {
    width: 200px;
  }

  &__date-picker {
    top: -5px;
    width: 140px;
  }
}