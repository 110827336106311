@import "compass/compass/css3";
.survey-modal {
  position: absolute;
  z-index: 100;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: .1s;

  .title__name {
    overflow-x: auto;
    white-space: nowrap;
    font-size: 1.5rem;
    width: fit-content;
  }

  &__grayed-background {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .loading-spinner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__box {
    position: absolute;
    width: 90vw;
    height: 90vh;
    top: 5%;
    left: 5%;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 2px;
    background-color: #ffffff;
  }

  &__content {
    padding: 20px 15px 0 50px;
    height: calc(90vh - 125px);
    overflow: auto;
  }

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__title-row {
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 14px 8px 14px 50px;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
    font-weight: 900;
    align-items: flex-start;
    max-height: 60px;

    &--edit {
      align-items: center;
    }

    h6 {
      display: flex;
      flex-direction: column;
    }

    span {
      font-size: .875rem;
    }
  }

  &__action-buttons {
    display: flex;
    justify-content: space-between;

    & > button {
      margin-left: 8px;
    }

  }

  &__save-button {
    width: 100px;
  }

  &__labels-button {
    width: fit-content;
  }

  &__close-button {
    margin-left: 0!important;
    width: 48px;
    height: 48px;
    margin-right: 8px !important;
    min-width: 48px !important;
  }

  &__edit-button {
    display: flex;
    align-items: center;
  }

  &__batch-record-button {
    margin-left: 32px;
  }

  &__view-mode {
    padding-right: 30px;

    .color-legend {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      width: 100%;

      &__item {
        break-inside: avoid;
        display: flex;
        flex-direction: row;
        margin: 10px;
      }

      &__icon {
        width: 24px;
        height: 24px;
        margin-right: 5px;
        outline: 1px solid black;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(0, 0, 0, 0.54) !important;

        &--gray {
          background-color: #f5f5f5;
        }

        &--dark-gray {
          background-color: #ababab;
        }

        &--blue {
          background-color: #90caf9;
        }

        &--green {
          background-color: #a5d6a7;
        }

        &--red {
          background-color: #ef9a9a;
        }

        &--yellow {
          background-color: #f9ca90;
        }
      }
    }

    .add-test {
      &__button {
        width: 250px;
      }
      &__card {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 12px;
        color: #818181;
        border: 1px solid #dadada;
        box-shadow: none;

        svg {
          fill: #818181;
          width: 27px;
          height: 27px;
        }

        h2 {
          font-size: 1.2rem;
        }
      }
    }

    .survey-notes {
      &__button {
        width: 250px;
        margin-bottom: 24px;
      }

      &__title {
        color: #818181;
        font-size: 1.2rem;
      }

      &__card {
        width: 100%;
        min-height: 59px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        overflow-wrap: anywhere;
        padding: 12px;
        border: 1px solid #dadada;
        box-shadow: none;
      }

      &__content {
        font-size: 0.875rem;
        div {
          min-height: 15px;
        }
      }
    }


    .survey-modal__test-cards {
      @include columns(14em);
      @include column-gap(3em);
      margin-bottom: 16px;
    }

  }

}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.MuiPaper-root {
  max-height: 80vh;
  overflow: auto;
}

.tooltip {
  margin-top: 4px;
  &__text {
    margin: 8px !important;
  }
}

.generate-labels-dialog {
  &__content {
    width: 460px;
    height: 400px;

    .dropdown-heading-dropdown-arrow {
      display: none;
    }
  }
}

.batch-record-title {
  h2 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
}

.survey-notes__text-field {
  width: 400px;

  textarea:first-of-type {
    min-height: 200px;
  }
}

