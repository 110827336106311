.card {
  width: 250px;
  min-height: 50px;
  margin: 0 8px 24px 0;
  max-height: unset;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  border: 1px solid #c3c3c3;
  border-radius: 4px;

  &__samples {
    max-height: 200px;
    overflow: auto;
  }

  &__expand-button {
    width: 100%;
    text-align: center;
    border-top: 1px solid #c3c3c3;

    button {
      padding: 0;
      width: 100%;
    }
  }

  &__divider {
    margin-bottom: 8px;

    &--sample {
      margin-left: 8px;
      margin-right: 8px;
      margin-bottom: unset;
    }
  }

  .sample-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4px;
    margin: 3px 8px;
    background-color: #f5f5f5;

    &--processed {
      background-color: #90caf9;
    }

    &--bad-sample {
      background-color: #f9ca90;
      text-decoration: line-through;
      font-style: italic;
    }

    &--absent {
      text-decoration: line-through;
      color: #a2a2a2;
      font-style: italic;
    }

    &--missing {
      background-color: #ababab;
      text-decoration: line-through;
      font-style: italic;
    }

    &--deleted {
      text-decoration: line-through;
      color: #a2a2a2;
      font-style: italic;
    }

    &__button {
      border-radius: 0;
      font-size: 16px;
      padding: 0;

      &:hover {
        color: black;
      }
    }

    &__buttons {
      display: flex;
    }

    &__select {
      width: 48px;
      svg {
        color: rgba(0, 0, 0, 0.54);
        right: 3px;
      }
      div {
        display: flex;
        padding: 3px;
      }
    }

    &__loading-spinner {
      width: 20px !important;
      height: 20px !important;
      padding: 2px;
      display: block !important;
    }

    &__text {
      max-width: 130px;
      overflow: auto;
    }

    label {
      margin-top: -8px;
      font-size: 0.875rem;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 !important;
    padding: 8px 8px 0;
    background-color: #f5f5f5;

    &--positive {
      background-color: #ef9a9a;
    }

    &--negative {
      background-color: #a5d6a7;
    }

    &--inconclusive {
      background-color: #d6c8a5;
    }

    &--processed {
      background-color: #90caf9;
    }

    &--subtitle {
      padding: 0 0 4px 8px;
      width: 100%;
    }

    .plus {
      fill: #864444;
    }

    .minus {
      fill: #448644;
    }

    .inconclusive {
      fill: #867944;
    }

    .title__button {
      padding: 0 2px;

      &--disabled {
        svg {
          fill: #aaaaaa !important;
        }
      }
    }

    .title__buttons {
      display: flex;
      margin-left: 8px;
    }

    .title__name {
      overflow-x: auto;
      white-space: nowrap;
      cursor: pointer;
      border-radius: 4px;
      font-size: 1.2rem;

      &:hover {
        background-color: #00000015;
      }
    }

  }
}


li {
  padding: 0 !important;

  .sample-row__button {
    border-radius: 0 !important;
    font-size: 16px !important;
    padding: 8px !important;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    span {
      margin-left: 8px;
    }
  }
}