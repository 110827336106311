@page {
  size: letter;
  margin: 0 0 0 0;
}

.MuiBox-root {
  border-left: 2px solid #d4d4d4;
}

.expand-cell {
  width: 10px;

  &--last {
    width: 20px;
  }
}

.table-head {
  th {
    font-weight: bold;
  }
  background-color: #d4d4d4;
}

.row {
  &--selected {
    background-color: #eff5fd;
  }
  &--uncategorized {
    background-color: #eaeaea;
  }
}

.title {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .buttons {
    display: flex;
    & > * {
      margin-left: 8px;
    }
  }
}

.person-name-textfield {
  width: 215px;
}

.print-population-table {
  max-height: unset;
  width: 8.5in !important;
  padding: 0 0.5in;

  .print-row--gray {
    background-color: #e0e0e0;
  }

  th {
    font-weight: bold;
  }

  td {
    padding: 4px;
  }
}

* {
  -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
  color-adjust: exact !important;                 /*Firefox*/
}

.drive-by-list {
  max-height: unset;
  width: 8.5in !important;
  padding: 0 0.5in;

  .print-row--gray {
    background-color: #e0e0e0;
  }

  th {
    font-weight: bold;
    padding-left: 0;
  }

  td {
    padding: 4px;
  }

  svg {
    width: 100px;
    height: 50px;
    margin-top: 5px;
  }
}


