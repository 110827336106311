.desktop-home {
  display: flex;
  flex-direction: column;

  &__app-bar {
    z-index: 90!important;
  }

  &__toolbar {
    display: flex;
    justify-content: space-between;
  }

  &__action-buttons {
    display: flex;
    justify-content: space-between;
    width: 250px;
  }
}