.scanner {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__app-bar {
    z-index: 90!important;
  }

  &__toolbar {
    display: flex;
    justify-content: space-between;
  }

  &__action-buttons {
    display: flex;
    justify-content: space-between;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e1e1e1;
    margin: 0 5% 16px 5%;
    padding-bottom: 8px;
  }

}

.video__container {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 8px;

  .video {
    height: 150px;
    outline: 2px solid #ff0000;

    &--scanning {
      outline-color: #00ff00;
    }
  }
}

.test-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &--empty {
    height: 100%;
  }

  .card {
    width: 90%;
    margin: 0;

    &__expand-button {
      display: none;
    }

    &__samples {
      max-height: calc(100vh - 294px);
    }
  }

  .next-test-button {
    margin: 8px 0;
    width: 90%;

  }
}

.special-text {
  font-weight: bold;
  font-family: "Courier New";
  white-space: pre;
  position: relative;

  svg {
    position: absolute;
    left: -1px;
    top: -4px;
    width: 20px;
  }
}

.multi-select {
  margin-top: 36px;

  .select-panel {
    max-height: 237px;
  }
}




