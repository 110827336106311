.MuiTableRow-root {
  cursor: pointer;
}

.MuiTableRow-head {
  cursor: default;
}

.result-count {
  font-weight: bold !important;

  &--negative {
    color: #009900 !important;
  }

  &--positive {
    color: #ca0202 !important;
  }

  &--inconclusive {
    color: #caa902 !important;
  }
}

.progress-spinner {
  display: flex;
  justify-content: center;
  margin: 50px 0;
  width: 100%;
}