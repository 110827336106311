@page {
  size: letter;
  margin: 0 0 0 0;
}

.loading-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.labels__container {
  cursor: pointer;
  height: calc(11in - 1in);
  width: calc(8.5in - .3in);
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: .5in 0 .5in .3in;
  outline: 1px solid black;

  &--loading {
    opacity: 0;
  }

  .label {
    position: relative;
    font-size: 11px;
    width: 1.75in;
    height: 0.5in;
    border: 1px solid black;
    border-radius: 5px;
    box-sizing: border-box;
    margin-right: .3in;
    display: flex;
    justify-content: space-between;
    padding: 3px;

    &__content {
      min-width: 80px;
      overflow: hidden;

      div:first-of-type {
        font-weight: bold;
      }

      div:last-of-type {
        white-space: nowrap;
      }
    }

    svg {
      height: 40px;
    }
  }
}
